<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="contact_information_form">
        <b-row>
          <b-col md="12" v-if="footers.length>0">
            <h6>{{ $t('Menus') }}</h6>

            <draggable
                :list="footers"
                :group="{name:'tags', pull:'clone', put:false }"
                class="mb-1 cursor-move"
            >
              <b-badge
                  v-for="(listItem, index) in footers"
                  :key="index"
                  class="mr-25"
              >
                <span @click="selectedRow(listItem)">{{ listItem.title }}</span>
              </b-badge>
            </draggable>
          </b-col>

          <b-col md="4">
            <b-card-text>
              <span>{{ $t('Title')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Title')"
                  rules="required"
              >
                <b-form-input name="title"
                    v-model="selected_row.title"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Write title here')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Type</span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
            >
              <b-form-radio-group
                  v-model="f_type"
                  :options="options"
                  stacked
                  name="is_link"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-card-text>
              <span>Status</span>
            </b-card-text>
            <validation-provider
                #default="{ errors }"
                name="status"
                rules="required"
            >
              <b-form-radio-group
                  v-model="is_active"
                  :options="options2"
                  stacked
                  name="is_active"
              >
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col v-if="f_type===1" md="4">
            <b-card-text>
              <span>{{ $t('Link')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('link')"
                  rules="required"
              >
                <b-form-input name="link"
                              v-model="selected_row.link"
                              :state="errors.length > 0 ? false:null"
                              :placeholder="$t('Write targeted url here')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="f_type===0" md="12">
            <b-card-text>
              <span>{{ $t('Description')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Description')"
                  rules="required"
              >
                <vue-editor v-model="selected_row.description" name="description"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('Write description here')" rows="5" ></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="secondary"
                type="button" @click="clear()"
            >
              {{ $t('Clear') }}
            </b-button>
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              {{ $t('Submit') }}
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BButton,BCardText,BFormGroup,BBadge,BFormRadioGroup,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import {
  required,email,
} from '@validations'
import apiCall from "@/libs/axios";
import { VueEditor } from "vue2-editor";
export default {
  name:'Footer',
  props:{type:{required:true}},
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,draggable,
    BRow,BFormGroup,
    BCol,BBadge,BFormRadioGroup,
    BButton,
    BCardText,VueEditor,
  },
  data() {
    return {
      required,email,
      selected_row:{},
      f_type:0,
      is_active:1,
      options: [
        { text: "Link", value: 1 },
        { text: "Description", value: 0 },
      ],
      options2: [
        { text: "Active", value: 1 },
        { text: "Inactive", value: 0 },
      ],
      footers:[],
    }
  },
  created(){
      if(this.all_footers.length <1) this.$store.dispatch('GET_ALL_FOOTER');
      this.getFooter();
  },
  methods: {
    selectedRow(row){
      this.selected_row=row;
      this.f_type=row.is_link;
      this.is_active=row.is_active;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.contact_information_form);
          if(this.f_type===0) data.append('description',this.selected_row.description);
          data.append('type',this.type);
          data.append('order','0');
          if(!this.selected_row.id){
            apiCall.post('/footer/information/store',data).then((response)=>{
              if (response.data.status==='success'){
                this.$refs.contact_information_form.reset();
                this.f_type=0;
                this.is_active=1;
                this.$refs.simpleRules.reset();
                this.$store.dispatch('GET_ALL_FOOTER');
                this.$toaster.success(response.data.message);
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else{
            apiCall.post(`/footer/information/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.$refs.contact_information_form.reset();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_FOOTER');
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    clear(){
      this.selected_row={};
      this.$refs.simpleRules.reset();
    },
    getFooter(){
      this.footers = this.all_footers.filter(item =>parseInt(item.type)===parseInt(this.type));
    }
  },
  computed:{
    all_footers(){
      return this.$store.getters.footers;
    }
  },
  watch:{
    type(){
      this.getFooter();
      this.selected_row={};
      this.f_type=0;
      this.is_active=1;
    },
    all_footers(){
      this.getFooter();
      this.selected_row={};
      this.f_type=0;
      this.is_active=1;
    }
  }
}
</script>